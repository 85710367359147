<template>
  <div class="download">
    <img src="https://abc.xnrun.com/image/default/48A0E4491F2F4EAAB8E695C88545A47F-6-2.png"
    alt="">
    <!-- <div class="button">
      <el-button round="">Android</el-button>
      <el-button round="">Iphone</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Download',
}
</script>

<style lang="scss" scoped>
  .download{
    width: 100vw;
    // min-height: 60vw;
    // background-image: url('../assets/imgs/d/d.jpg');
    // background-size: cover;
    // background-position: left bottom;
    .button{
      position: absolute;
      top:65vh;
      left:10vw;
      .el-button{
        font-weight: bolder;
        margin-right: 30px;
        width: 130px;
        border: 1px solid black;
        color: black;
        &:focus, &:hover{
          color:#FEA600;
          border-color: #FEA600;
          background-color: #fff;
        }
      }
    }
  }
</style>
